import { TProduct } from '@contimo/api/src/api/products';
import { TSellingPriceUpdateBody } from '@contimo/api/src/api/sellingPrices';
import germanDecimalParser from '@/utils/germanDecimalParser';

export const setUpdateProduct = (self: any, data: TProduct) => {
  const { currentSellingPrice, ...product } = data;

  if (product.counts?.orders === 0) self.canUpdateProduct = true;
  else self.canUpdateProduct = false;

  const { id, type, number, container, deleted } = product;
  self.productForm = {
    id,
    type,
    number,
    deleted,
    container: {
      size: container.size,
      type: container.type,
      flap: container.flap,
      cover: container.cover,
      doors: container.doors,
      length: container.length,
      width: container.width,
      vehiclePassageWidth: container.vehiclePassageWidth,
      vehicleHeadroom: container.vehicleHeadroom,
      vehicleManeuveringAreaLength: container.vehicleManeuveringAreaLength,
      vehicleManeuveringAreaWidth: container.vehicleManeuveringAreaWidth,
      vehicleLength: container.vehicleLength,
      vehicleOverallLength: container.vehicleOverallLength,
      vehicleTotalWeight: container.vehicleTotalWeight,
    },
  };

  if (currentSellingPrice) {
    const { name, basePrice, containerSellingPrice } = currentSellingPrice;
    self.sellingPriceForm = {
      id: currentSellingPrice.id,
      name,
      basePrice: basePrice ? germanDecimalParser(basePrice, true) : 0,
      containerSellingPrice: {
        durationPriceAfter: containerSellingPrice.durationPriceAfter
          ? containerSellingPrice.durationPriceAfter
          : 0,
        durationPricePerDay: containerSellingPrice.durationPricePerDay
          ? germanDecimalParser(containerSellingPrice.durationPricePerDay, true)
          : 0,
        pricePerDeliveryKm: containerSellingPrice.pricePerDeliveryKm
          ? germanDecimalParser(containerSellingPrice.pricePerDeliveryKm, true)
          : 0,
      },
    };

    self.currentSellingPrice = JSON.parse(JSON.stringify(self.sellingPriceForm));
  }
  setTimeout(() => {
    self.loading = false;
  }, 500);
};

export const diffPricingChanges = (self: any, newVal: TSellingPriceUpdateBody) => {
  const { basePrice: oldBasePrice, containerSellingPrice } = self.currentSellingPrice;
  const oldBasePriceVal = oldBasePrice ? germanDecimalParser(oldBasePrice) : 0;
  const newBasePriceVal = newVal.basePrice ? germanDecimalParser(newVal.basePrice) : 0;
  const basePriceChanged = oldBasePriceVal !== newBasePriceVal;
  let contPriceChanged = false;
  Object.keys(newVal.containerSellingPrice).forEach((key: string) => {
    if (!contPriceChanged && ['durationPricePerDay', 'pricePerDeliveryKm'].includes(key)) {
      // @ts-ignore
      const current = newVal.containerSellingPrice[key].toString();
      const newPrice = germanDecimalParser(current);
      const oldPrice = germanDecimalParser(containerSellingPrice[key]);
      contPriceChanged = newPrice !== oldPrice;
    } else if (!contPriceChanged && key === 'durationPriceAfter') {
      const oldDurationVal = Number(containerSellingPrice[key]);
      const newDurationVal = Number(newVal.containerSellingPrice[key]);
      contPriceChanged = oldDurationVal !== newDurationVal;
    }
  });
  if (basePriceChanged || contPriceChanged) {
    self.shouldUpdateSellingPrice = true;
  } else {
    self.shouldUpdateSellingPrice = false;
  }
};

export const updateOrCreateSellingPrice = async (self: any) => {
  const { basePrice, name, containerSellingPrice } = self.sellingPriceForm;
  const { durationPriceAfter, durationPricePerDay, pricePerDeliveryKm } = containerSellingPrice;
  const sellingPriceBody = {
    basePrice: germanDecimalParser(basePrice),
    name,
    containerSellingPrice: {
      durationPriceAfter: germanDecimalParser(durationPriceAfter),
      durationPricePerDay: germanDecimalParser(durationPricePerDay),
      pricePerDeliveryKm: germanDecimalParser(pricePerDeliveryKm),
    },
  };
  await self.updateSellingPrice({
    id: self.sellingPriceForm.id,
    body: sellingPriceBody,
  });
};
