<template>
  <CModal
    :show="show"
    variant="dialog"
    submit-variant="danger"
    header-icon="exclamation-circle"
    :submit-countdown="1"
    :submit-loading="loading"
    @toggle="onToggle"
    @submit="onDelete"
  >
    <template v-slot:header>
      {{ $t('actionQuestion.confirmDelete') }}
    </template>
    <CTypo>{{ $t('products.container.reallyWantToDelete') }}</CTypo>
    <template v-slot:submit>
      {{ $t('delete') }}
    </template>
  </CModal>
</template>

<script>
import { mapActions } from 'vuex';
import { DELETE_PRODUCT } from '@/store/actionTypes';

export default {
  model: {
    prop: 'show',
    event: 'toggle',
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    ...mapActions([DELETE_PRODUCT]),
    onToggle(show) {
      this.$emit('toggle', show);
    },
    onDelete() {
      this.loading = true;
      this.deleteProduct({
        id: this.productId,
      }).then(() => {
        this.loading = false;
        this.onToggle(false);
        this.$emit('deleted');
      });
    },
  },
};
</script>
