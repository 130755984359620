<template>
  <div>
    <CTable
      v-if="pagination"
      ref="table"
      :data="showDeleted ? deletedContainerProducts : containerProducts"
      paginated
      :total="pagination.total"
      :per-page="perPage"
      :current-page="pagination.currentPage"
      sticky-header
      :box="box"
      mobile-cards
      mobile-breakpoint="440px"
      v-bind="{ ...$attrs }"
      hoverable
      @page-change="onChangePage"
      @row-click="onRowClick"
    >
      <o-table-column
        field="number"
        :label="$t('products.container.number')"
        width="90"
        sortable
        numeric
        v-slot="{ row }"
      >
        <template v-if="row.container.type !== 'bigbag1'">
          {{ row.number }}
        </template>
        <template v-else>
          &infin;
        </template>
      </o-table-column>
      <o-table-column
        field="container.size"
        :label="$t('products.container.size')"
        width="90"
        sortable
        numeric
        v-slot="{ row }"
      >
        {{ row.container.size }} m&sup3;
      </o-table-column>
      <o-table-column
        field="container.type"
        :label="$t('products.container.type')"
        width="auto"
        sortable
        v-slot="{ row }"
      >
        <div :color="getContainerTypeColor(row.container.type)" class="flex items-center">
          <div>
            {{ $t(`products.container.types.${row.container.type}`) }}
            <CContainerOpeningType 
              :container="row.container" 
              class="text-xs font-normal text-gray-700"
            />
          </div>
          
          <div>
            <CTypo
              v-if="row.deleted"
              el="span"
              tstyle="caption1"
              class="ml-2 text-red-500"
            >
              {{ $t('deleted') }}
            </CTypo>
          </div>
        </div>
      </o-table-column>
      <template v-slot:empty>
        <div class="text-center py-10">
          <div class="inline-flex justify-center text-gray-500">
            <CIcon name="plus-circle" outline size="32" />
          </div>
          <CTypo tstyle="body2" class="mt-1">
            {{ $t('noItemsExisting', { name: $t('container') }) }}
          </CTypo>
          <CTypo class="mt-1 text-gray-500">
            {{ $t('getStartedByCreateFirst', { name: $t('container') }) }}
          </CTypo>
          <div class="mt-6">
            <CButton @click="showCreateUpdateModal = true">
              {{ $t('products.container.add') }}
            </CButton>
          </div>
        </div>
      </template>
    </CTable>
    <Portal to="overlays">
      <CreateUpdateModal
        v-model="showCreateUpdateModal"
        :update-id.sync="updateId"
        @toggle="onModalToggle"
        @delete="onDelete"
      />
      <DeleteDialog
        v-model="deleteDialog"
        :product-id="deleteId"
        @deleted="showCreateUpdateModal = false"
      />
    </Portal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_PRODUCTS } from '@/store/actionTypes';
import { CONTAINER_PRODUCTS, DELETED_CONTAINER_PRODUCTS } from '@/store/gettersTypes';
import CreateUpdateModal from './CreateUpdateModal.vue';
import DeleteDialog from './DeleteDialog.vue';

export default {
  components: {
    CreateUpdateModal,
    DeleteDialog,
  },

  inheritAttrs: false,

  props: {
    box: {
      type: Boolean,
      default: false,
    },
    showCreateModal: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    showDeleted: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showCreateUpdateModal: false,
    deleteDialog: false,
    deleteId: null,
    updateId: null,
  }),

  computed: {
    ...mapGetters([CONTAINER_PRODUCTS, DELETED_CONTAINER_PRODUCTS]),
    pagination() {
      return this.$store.state.products.pagination;
    },
  },

  watch: {
    showCreateModal: {
      immediate: true,
      handler(newVal) {
        this.showCreateUpdateModal = newVal;
        if (newVal) this.updateId = null;
      },
    },
    showDeleted(newVal) {
      this.getProducts({ page: 1, limit: this.perPage, showDeleted: newVal });
    },
  },

  created() {
    this.getProducts({ page: 1, limit: this.perPage, showDeleted: this.showDeleted });
  },

  methods: {
    ...mapActions([GET_PRODUCTS]),
    onChangePage(page) {
      this.getProducts({ page, limit: this.perPage, showDeleted: this.showDeleted });
    },
    getContainerTypeColor(type) {
      if (type === 'rolloff') return 'green';
      if (type === 'dropoff') return 'yellow';
      return 'red';
    },
    onRowClick(rowData) {
      this.updateId = rowData.id;
      this.$nextTick(() => {
        this.showCreateUpdateModal = true;
      });
    },
    onModalToggle() {
      if (this.showCreateModal && !this.showCreateUpdateModal) {
        this.$emit('update:showCreateModal', false);
      }
    },
    onDelete({ id }) {
      this.deleteId = id;
      this.deleteDialog = true;
    },
  },
};
</script>
